import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Creating sticky header at the top of a page",
  "description": "How to create a sticky element when reaching a threshold of scrolling",
  "date": "2019-06-09",
  "image": "sticky-header.jpg",
  "tags": ["javascript", "css", "frontend"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What we want to achieve`}</h2>
    <undefined><div {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "/65dbd841bc7d11b4c22a90752f063f24/demo.gif",
          "alt": "demo"
        }}></img>
      </div>{`
There are a few ways to make the header stick to the view.
In this tutorial, I will show you some solutions that I found.`}</undefined>
    <h2>{`Using CSS`}</h2>
    <p>{`The most straightforward way is to use css `}<a rel="noopener" href="https://developer.mozilla.org/en-US/docs/Web/CSS/position" target="_blank">{`position: sticky`}</a>{` position.
This css attribute enables us to stick an element to the viewport`}</p>
    <p>{`Let's say we have our html as below`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`app-container`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`header`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`app-header`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    header
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`header`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      imperdiet ac est vitae convallis. Mauris sed neque ac metus pretium
      fermentum non sit amet lacus. Donec tristique erat at nunc blandit
      interdum. Vestibulum imperdiet libero ac sapien tempus ultricies. Cras et
      arcu ut nunc faucibus efficitur facilisis nec lorem. Duis ac placerat
      tortor. In nibh ex, tristique id malesuada vel, pharetra non turpis.
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      Etiam tempus lorem dui, eget placerat massa blandit et. Aliquam erat
      volutpat. Mauris fringilla blandit congue. Pellentesque imperdiet mauris
      et eros tempor ultrices. Cras eu aliquet magna. Pellentesque at placerat
      ex. Morbi condimentum tincidunt urna, vel elementum lorem efficitur nec.
      Pellentesque venenatis feugiat nibh non malesuada. Ut id aliquet nulla.
      Aenean magna massa, ornare in diam sit amet, congue consequat tortor. Sed
      venenatis congue nisl, sed eleifend nibh molestie eu.
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      Orci varius natoque penatibus et magnis dis parturient montes, nascetur
      ridiculus mus. Donec consequat odio sed neque semper, eget rutrum sapien
      ornare. Nunc eget varius tortor. Vestibulum iaculis diam a risus volutpat
      accumsan. Maecenas sit amet imperdiet leo. Aenean eleifend interdum
      pulvinar. Vestibulum lobortis ligula ut eros consectetur, a sollicitudin
      mi mattis. Donec gravida velit ut nisl mattis hendrerit. In at facilisis
      metus. Curabitur gravida ipsum eget urna lacinia euismod. Etiam in lorem
      facilisis enim tempus auctor. Curabitur eget massa risus. Ut elementum sem
      dictum tellus mollis pulvinar. Duis rhoncus turpis a magna dapibus
      pellentesque. Mauris bibendum eu nulla sit amet sodales. Fusce eget
      sodales purus.
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- another long content        -->`}</span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`and css`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.app-container`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 auto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 750px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.app-header`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #282c34`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`min-height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 50px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 750px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`flex-direction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` column`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`calc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`10px + 2vmin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To make our header stick to our view, we can specify our header like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.app-header`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -webkit-sticky`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Safari */`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sticky`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* other properties */`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As MDN explains:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sticky positioning can be thought of as a hybrid of relative and fixed positioning.
A stickily positioned element is treated as relatively positioned until it crosses a specified threshold, at which point it is treated as fixed until it reaches the boundary of its parent.`}</p>
    </blockquote>
    <p>{`This means that the css value will act as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: relative`}</code>{` within its parents until it reaches a specific threshold.
In our example, the header will stay relative to our container until we start scrolling. That is because we specified our threshold at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top: 0`}</code>{`.
To illustrate it clearer, let's make our header sticky at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top: 20px`}</code>{` and give some content above the header. Now it would look like this:`}</p>
    <undefined><div {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "/fcc1cecdd5f25c33e0c251e6c123a6d9/demo1.gif",
          "alt": "demo1"
        }}></img>
      </div>{`
Pretty neat and simple right? However, the support for this value is quite poor. 
See
`}<a {...{
        "rel": ["noopener"],
        "href": "https://caniuse.com/#search=sticky",
        "target": "_blank"
      }}>{` caniuse `}</a>{` 
for more info.
To make it work across browsers, we need to apply Polyfill to make it work well with a wide range of browsers.`}</undefined>
    <h2>{`Using JS and CSS`}</h2>
    <p>{`If you are not a fan of the above solution and want to find a more stable cross-browsers solution, we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: fixed`}</code>{`
to position our header when we scroll down, but we need an assistance of JS to make it happen.`}</p>
    <p>{`Basically, what we are trying to do here is that we will detect whether users scroll away from the top and decide when to attach the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: fixed`}</code>{` to our header.
By this way, our header will not be out of the view and stick at the top.
Let's create a class `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sticky`}</code>{` to make it easy to add in JS.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.sticky`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` fixed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In JS, we will listen to sroll event of the page by using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onscroll`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` header `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` topOfHeader `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`offsetTop

window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'scroll'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pageYOffset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` topOfHeader`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'sticky'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`remove`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'sticky'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`offsetTop`}</code>{` will return the `}<a rel="noopener" href="https://www.w3schools.com/jsref/prop_element_offsettop.asp" target="_blank">{`top position (in pixels) relative to the top of the offsetParent element`}</a>{`.
In our case, it will return the top position of the header relative to the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`app-container`}</code>{`, which is also counting from the top of our viewport.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`window.pageYOffset`}</code>{` will return the number indicating how far we have scrolled long the horizontal axis.`}</li>
    </ul>
    <p>{`This works pretty well so far, but there is one bugging issue. When we our header become `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fixed`}</code>{`, the content jumps up abruptly. This is because when we apply `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: fixed`}</code>{` to the header, it is no longer taking up space in the document.
Therefore, the section will jump up to take up the exact height of the header.
You can see the demo in this `}<a rel="noopener" href="https://codepen.io/Dragonza/pen/oRKmVj?editors=1111" target="_blank">{`pen`}</a></p>
    <p>{`To fix this, we need to add a padding top to the parent element the same value as the height of our header
and reset it when we scroll back to the top.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` header `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'header'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` container `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.app-container'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` topOfHeader `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`offsetTop

window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'scroll'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pageYOffset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` topOfHeader`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    container`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`style`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`paddingTop `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` header`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`offsetHeight `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'px'`}</span></span>{`    header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'sticky'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    container`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`style`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`paddingTop `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span></span>{`    header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`classList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`remove`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'sticky'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`offsetHeight`}</code>{` will return the height of an element including vertical padding and borders, as an integer`}</li>
    </ul>
    <p>{`You can see full demo at this `}<a rel="noopener" href="https://codepen.io/Dragonza/pen/oRKVKo?editors=1111" target="_blank">{`pen`}</a></p>
    <p>{`That is it! Thank you for taking time reading this blog!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      